import { BrowserRouter, Routes, Route } from 'react-router-dom'

import ProjectDataProvider from 'contexts/ProjectDataProvider'
// import Loading from 'pages/Loading'
import Dashboard from 'pages/Dashboard'
import BidPackage from 'pages/BidPackage'
import InvalidUrl from 'pages/InvalidUrl'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/:projectId' element={<ProjectDataProvider />}>
          <Route path=':tradeId' element={<BidPackage />} />
          <Route path='' element={<Dashboard />} />
        </Route>
        <Route path='*' element={<InvalidUrl />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
