import { useEffect, useState, useMemo } from 'react'
import { useNavigate, Outlet, useParams } from 'react-router-dom'
import ProjectContext, { ProjectContextT } from 'contexts/ProjectContext'
import _ from 'lodash'
import { NoteT, ProjectDataT, SowItemDictT } from 'model/types'
import { fetchData } from 'controllers/projects'
import Loading from 'pages/Loading'

const ProjectDataProvider = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<ProjectDataT | undefined>()
  const { projectId } = useParams()
  const [isDataLoading, setIsDataLoading] = useState(true)
  if (_.isNil(projectId)) {
    console.error('projectId missing in url')
    return null
  }

  const scope = useMemo(() => {
    if (data) {
      const excludedItems = _.get(data, 'scope.excludedScopeItems', [])
      const excludedItemsDict = _.keyBy(excludedItems)
      const sowItems = data.dicts.scopeItemsDict
      const pDetails = _.get(data.project, 'details', [])
      const res = {}
      _.forEach(sowItems, (sowItem: SowItemDictT) => {
        const applicable = sowItem.applicable
        let isNeeded = true
        if (!_.isEmpty(applicable)) {
          const ints = _.difference(applicable, pDetails)
          isNeeded = _.isEmpty(ints)
        }
        if (isNeeded && sowItem.tradeId && sowItem.sectionId && !_.has(excludedItemsDict, sowItem.id)) {
          _.set(res, [sowItem.tradeId, sowItem.sectionId, sowItem.id], sowItem)
        }
      })
      return res
    } else {
      return {}
    }
  }, [data])

  const notesByTrade = useMemo(() => {
    if (data) {
      const excludedItems = _.get(data, 'scope.excludedScopeItems', [])
      const excludedItemsDict = _.keyBy(excludedItems)
      const notesDict = data.dicts.notesDict
      const pDetails = _.get(data.project, 'details', [])
      const res = {}
      _.forEach(notesDict, (note: NoteT) => {
        const applicable = note.applicable
        let isNeeded = true
        if (!_.isEmpty(applicable)) {
          const ints = _.difference(applicable, pDetails)
          isNeeded = _.isEmpty(ints)
        }
        const tradeId = _.get(note, 'tradeId', 'empty')
        if (isNeeded && note.sectionId && !_.has(excludedItemsDict, note.id)) {
          _.set(res, [tradeId, note.sectionId, note.id], note)
        }
      })
      return res
    } else {
      return {}
    }
  }, [data])

  useEffect(() => {
    if (projectId) {
      const run = async () => {
        console.log('%cproject is not new: fetch Dicts', 'color: orange;')
        if (projectId) {
          const projectData = await fetchData(projectId)
          if (_.isNil(projectData)) {
            navigate('/')
          } else {
            setData(projectData)
            setIsDataLoading(false)
          }
        }
      }
      run()
    }
  }, [])

  if (data) {
    const contextValue: ProjectContextT = {
      isDataLoading,
      project: data.project,
      accountProfile: data.accountProfile,
      detailsDict: data.dicts.detailsDict,
      tradesDict: data.dicts.tradesDict,
      notesDict: data.dicts.notesDict,
      notesByTrade,
      scope,
      sectionsDict: data.dicts.scopeSections
    }

    return (
      <ProjectContext.Provider value={contextValue}>
        <Outlet />
      </ProjectContext.Provider>
    )
  } else {
    return <Loading />
  }
}

export default ProjectDataProvider
