import { Flex, HStack, Tag, TagLabel, Heading } from '@chakra-ui/react'
import { useContext } from 'react'
import _ from 'lodash'

import ProjectContext from 'contexts/ProjectContext'
// import { getName } from 'shared/utils/stringUtils'

const DashboardNavBar = () => {
  const { project, detailsDict } = useContext(ProjectContext)

  const projectDetails = _.get(project, 'details', [])

  const tags = _.map(projectDetails, d => {
    const info = _.get(detailsDict, d)
    return (
      <Tag key={d} bgColor='gray.400' size='sm'>
        <TagLabel color='white'>{info?.name}</TagLabel>
      </Tag>
    )
  })

  return (
    <Flex px={6} h='20' borderBottomWidth={'1px'} borderBottomColor={'gray.300'} shrink={0}>
      <HStack spacing={2} align='center'>
        {/* <Avatar src={_.get(accountProfile, 'avatarSmall', _.get(accountProfile, 'avatar'))} />
        <Heading size='md'>{getName(accountProfile)}</Heading> */}
        <Heading size='md'>{_.get(project, 'address.name')}</Heading>
        {/* <Text color='gray.600' pl={4} pr={8}>
          {_.get(project, 'address.name')}
        </Text> */}
        {tags}
      </HStack>
    </Flex>
  )
}

export default DashboardNavBar
