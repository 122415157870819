import { FC } from 'react'
import { Center, VStack, Box, Text, Heading } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'assets/masonLogoOnLight.svg'

type Props = {
  note?: string
}

const InvalidUrl: FC<Props> = ({ note }) => {
  return (
    <Center h='full' w='full'>
      <VStack w='xs' direction='column' align='center' spacing={4} pb={32}>
        <Logo height='24px' />
        <Box w='full' pt={12}>
          <Heading textAlign={'center'}>The URL is invalid</Heading>
          <Text textAlign={'center'} fontSize='sm' color={'gray.500'}>
            Make sure the URL is correct and try again
          </Text>
        </Box>
      </VStack>
    </Center>
  )
}

export default InvalidUrl
