import { Flex } from '@chakra-ui/react'
import BidPackageNavBar from 'pages/bidPackage/BidPackageNavBar'
import BidPackagePdf from 'pages/bidPackage/BidPackagePdf'
import BidPackageScope from 'pages/bidPackage/BidPackageScope'
import BidPackageSidePanel from 'pages/bidPackage/BidPackageSidePanel'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

const BidPackage = () => {
  const { tradeId } = useParams()
  const [blob, setBlob] = useState<Blob | undefined>()

  console.log('tradeId', tradeId)

  return (
    <Flex direction='row' w='full' h='full'>
      <BidPackageSidePanel blob={blob} />
      <Flex direction='column' w='full'>
        <BidPackageNavBar />
        <BidPackageScope />
      </Flex>
      <BidPackagePdf onBlob={b => setBlob(b)} />
    </Flex>
  )
}

export default BidPackage
