import { CSSProperties, JSXElementConstructor, ReactElement, useEffect, Ref } from 'react'
import { usePDF } from '@react-pdf/renderer'
import _ from 'lodash'

import { PDFVersion } from '@react-pdf/types'
// import Loading from 'pages/Loading'

interface OnRenderProps {
  blob?: Blob
}

interface DocumentProps {
  title?: string
  author?: string
  subject?: string
  creator?: string
  keywords?: string
  producer?: string
  language?: string
  pdfVersion?: PDFVersion
  onRender?: (props: OnRenderProps) => any
}

interface PDFViewerProps {
  title?: string
  width?: number | string
  height?: number | string
  style?: CSSProperties
  className?: string
  children?: ReactElement<DocumentProps>
  innerRef?: Ref<HTMLIFrameElement>
  zoom?: string
  onBlob?: (blob: Blob) => void
}

export const PDFZoomViewer = ({
  title,
  style,
  className,
  children,
  innerRef,
  zoom,
  onBlob,
  ...props
}: PDFViewerProps) => {
  const [instance, updateInstance] = usePDF({
    document: children as ReactElement<DocumentProps, string | JSXElementConstructor<any>>
  })

  console.log('instance loading', instance.loading)
  console.log('instance url', instance.url)

  useEffect(() => {
    if (!_.isNil(instance.blob) && onBlob) {
      onBlob(instance.blob)
    }
  }, [instance.blob])

  useEffect(updateInstance, [children])

  // if (instance.loading) {
  //   return <Loading note='Pdf report generation...' />
  // } else {
  //   return (
  //     <iframe
  //       title={title}
  //       ref={innerRef}
  //       style={style}
  //       src={instance.url ? `${instance.url}${zoom ? `#zoom=${zoom}` : ''}` : undefined}
  //       className={className}
  //       {...props}
  //     />
  //   )
  // }
  return null
}

export default PDFZoomViewer
