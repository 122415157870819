import { FC } from 'react'
import _ from 'lodash'
import { Text, View, Image } from '@react-pdf/renderer'
import moment from 'moment'
import styles from 'components/scopeReport/styles'
import { AccountProfileT, ProjectT } from 'model/types'
import { getAddress, getName } from 'shared/utils/stringUtils'

type Props = {
  accountProfile: AccountProfileT
  project: ProjectT
}

const ScopeReportHeader: FC<Props> = ({ accountProfile, project }) => {
  const avatarUrl = _.get(accountProfile, 'avatarSmall', _.get(accountProfile, 'avatar'))
  let projectAddress = _.get(project, 'participantDetails.projectAddress', '')
  if (_.isEmpty(projectAddress)) projectAddress = getAddress(project.address)
  const dateNow = moment().format('L')

  let gcName = _.get(project, 'participantDetails.gcName')
  if (_.isEmpty(gcName)) gcName = getName(accountProfile)

  let gcAddress = _.get(project, 'participantDetails.gcAddress')
  if (_.isEmpty(gcAddress)) gcAddress = getAddress(accountProfile.address)

  let gcEmail = _.get(project, 'participantDetails.gcEmail')
  if (_.isEmpty(gcEmail)) gcEmail = accountProfile.email

  let gcPhone = _.get(project, 'participantDetails.gcPhone')
  if (_.isEmpty(gcPhone)) gcPhone = accountProfile.phone

  const renderLine = (t: string | undefined) => {
    if (!_.isEmpty(t)) {
      return <Text style={{ ...styles.textSmall, marginBottom: 4 }}>{t}</Text>
    }
  }

  // const preparedFor = (
  //   <View style={{ flex: 1.5, flexDirection: 'column', paddingLeft: 16, marginVertical: 4 }}>
  //     <Text style={{ ...styles.textSmall, fontWeight: 'bold', marginBottom: 4 }}>Prepared for:</Text>
  //     {renderLine(getName(_.get(project, 'participantDetails')))}
  //     {renderLine(project.participantDetails?.customerAddress)}
  //     {renderLine(project.participantDetails?.customerEmail)}
  //     {renderLine(project.participantDetails?.customerPhone)}
  //   </View>
  // )

  const preparedBy = (
    <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 5, marginVertical: 4 }}>
      <Text style={{ ...styles.textSmall, fontWeight: 'bold', marginBottom: 4 }}>Requested by:</Text>
      {renderLine(gcName)}
      {renderLine(gcAddress)}
      {renderLine(gcEmail)}
      {renderLine(gcPhone)}
    </View>
  )

  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
          {avatarUrl && <Image src={avatarUrl} style={{ width: 50, maxHeight: 20 }} />}
        </View>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <Text style={{ ...styles.heading, color: '#4F4F4F' }}>PROJECT ADDRESS</Text>
          <Text style={{ ...styles.textMedium, color: '#003256', fontWeight: 'semibold', marginTop: 1 }}>
            {projectAddress}
          </Text>
        </View>
        <View style={{ flex: 1, flexDirection: 'column', alignItems: 'flex-end' }}>
          <Text style={{ ...styles.textLarge, color: '#003256', fontWeight: 'semibold' }}>SCOPE OF WORK</Text>
          <Text style={{ ...styles.textSmall, marginTop: 4 }}>{dateNow}</Text>
        </View>
      </View>

      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
        <View style={{ width: 1.5, backgroundColor: '#003256', height: '100%' }} />
        {preparedBy}
      </View>
    </View>
  )
}

export default ScopeReportHeader
