import { FC } from 'react'
import _ from 'lodash'
import { View, Text } from '@react-pdf/renderer'
import styles from 'components/scopeReport/styles'
import { roundRobinGet } from 'shared/utils/array'
import itemsColors from 'constants/itemsColors'
import { SowItemDictT } from 'model/types'

type Props = {
  sowItems: SowItemDictT[]
  sectionName: string
  sectionId: string
  index: number
}

const ReportSection: FC<Props> = ({ sowItems, sectionName, sectionId, index }) => {
  const bgColor = roundRobinGet(itemsColors, index)
  return (
    <View key={sectionId} style={{ flexDirection: 'column', paddingTop: 10 }}>
      <View style={{ flexDirection: 'row' }}>
        <Text
          style={{
            ...styles.textSmall,
            fontWeight: 'semibold',
            backgroundColor: bgColor,
            marginLeft: 11,
            paddingHorizontal: 5,
            paddingVertical: 1,
            borderRadius: 1
          }}
        >
          {sectionName}
        </Text>
        <View style={{ flex: 1 }} />
      </View>
      {_.map(sowItems, (sowItem: SowItemDictT) => {
        return (
          <View
            key={sowItem.id}
            style={{ marginLeft: 30, marginTop: 4, flexDirection: 'row', alignItems: 'flex-start' }}
            wrap={false}
          >
            <Text style={{ ...styles.textMedium, color: '#004069' }}>•</Text>
            <Text style={{ ...styles.textXSmall, color: '#004069', marginLeft: 4 }}>{sowItem.name}</Text>
          </View>
        )
      })}
    </View>
  )
}

export default ReportSection
