import { useContext, useState, useMemo } from 'react'
import _ from 'lodash'
import { Box, Heading, VStack, Flex, Text, Collapse, StackDivider } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ProjectContext from 'contexts/ProjectContext'
import itemsColors from 'constants/itemsColors'
import { roundRobinGet } from 'shared/utils/array'
import { SowItemDictT } from 'model/types'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import ScopeItemRow from 'pages/bidPackage/ScopeItemRow'

const BidPackageScope = () => {
  const { tradeId } = useParams()
  const { tradesDict, scope, sectionsDict, notesByTrade } = useContext(ProjectContext)
  const [closedSections, setClosedSections] = useState({})

  const sections = tradeId && _.get(scope, tradeId)

  const notesSections = useMemo(() => {
    if (!tradeId) return {}
    const res = {}
    const generalNotes = _.get(notesByTrade, 'empty', {})
    _.forEach(generalNotes, (lst, sectionId) => {
      _.forEach(lst, (note, noteId) => _.set(res, [sectionId, noteId], note))
    })
    const tradeNotes = _.get(notesByTrade, tradeId, {})
    _.forEach(tradeNotes, (lst, sectionId) => {
      _.forEach(lst, (note, noteId) => _.set(res, [sectionId, noteId], note))
    })
    return res
  }, [notesByTrade])

  console.log('notesByTrade', notesByTrade)
  console.log('notesSections', notesSections)

  const toggleSection = (sectionId: string) => {
    const key = sectionId
    const open = !_.get(closedSections, key, false)
    setClosedSections(closedSections => ({ ...closedSections, [key]: open }))
  }

  const renderSection = (sowItems: object, sectionId: string, index: number) => {
    const bgColor = roundRobinGet(itemsColors, index)
    const open = !_.get(closedSections, sectionId, false)
    return (
      <Box key={sectionId} w='full'>
        <Flex
          direction={'row'}
          w='fit-content'
          align='center'
          gap={2}
          as='button'
          onClick={() => toggleSection(sectionId)}
        >
          <Box transform={open ? 'rotate(90deg)' : undefined} transitionDuration={'0.2s'}>
            <FontAwesomeIcon icon={faCaretRight} />
          </Box>
          <Text bgColor={bgColor} px={4} rounded={'base'}>
            {_.get(sectionsDict, [sectionId, 'name'])}
          </Text>
        </Flex>
        <Collapse in={open}>
          <VStack align={'flex-start'} divider={<StackDivider borderColor='gray.200' />} pt={2} pl={4} spacing={0}>
            {_.map(_.sortBy(sowItems, 'name'), (sowItem: SowItemDictT) => {
              return <ScopeItemRow key={sowItem.id} itemId={sowItem.id} name={sowItem.name} />
            })}
          </VStack>
        </Collapse>
      </Box>
    )
  }

  const zonesIds = _.sortBy(_.keys(sections), sectionId => _.get(sectionsDict, [sectionId, 'name']))

  const renderNotes = () => {
    const notesSectionsIds = _.sortBy(_.keys(notesSections), sectionId => _.get(sectionsDict, [sectionId, 'name']))
    if (!_.isEmpty(notesSectionsIds)) {
      return (
        <>
          <Heading size='md' color={'blue.600'} pt={12}>
            General notes
          </Heading>
          <VStack flex={1} align={'flex-start'} pt='4' spacing={4} w='full'>
            {_.map(notesSectionsIds, (sectionId, index) =>
              renderSection(_.get(notesSections, sectionId), sectionId, index)
            )}
          </VStack>
        </>
      )
    }
  }

  return (
    <Flex w='full' h='full' px={8} py={4} direction='column' overflowX='hidden' overflowY={'auto'}>
      <Heading size='md' color={'blue.600'}>
        {tradeId && _.get(tradesDict, tradeId)}
      </Heading>
      <VStack flex={1} align={'flex-start'} pt='4' spacing={4} w='full'>
        {_.map(zonesIds, (sectionId, index) => renderSection(_.get(sections, sectionId), sectionId, index))}
      </VStack>
      {renderNotes()}
    </Flex>
  )
}

export default BidPackageScope
