import { FC, useContext, useMemo } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { Document, Page, Font, Text, View } from '@react-pdf/renderer'

import ProjectContext from 'contexts/ProjectContext'
import PDFViewer from 'components/PdfViewer'
import ScopeReportHeader from 'components/scopeReport/ScopeReportHeader'
import styles from 'components/scopeReport/styles'
import ScopeSection from 'components/scopeReport/ScopeSection'

Font.register({
  family: 'Lato',
  fonts: [
    { src: '/fonts/latoregular.ttf' },
    { src: '/fonts/latosemibold.ttf', fontWeight: 600 },
    { src: '/fonts/latobold.ttf', fontWeight: 700 }
  ]
})

type Props = {
  onBlob?: (blob: Blob) => void
}

const BidPackagePdf: FC<Props> = ({ onBlob }) => {
  const { tradeId } = useParams()
  const { tradesDict, scope, sectionsDict, notesByTrade, project, accountProfile } = useContext(ProjectContext)

  const filename = tradeId ? `${_.get(project, 'address.name', '')} ${_.get(tradesDict, tradeId, '')} scope` : ''

  const sections = tradeId && _.get(scope, tradeId)

  const notesSections = useMemo(() => {
    if (!tradeId) return {}
    const res = {}
    const generalNotes = _.get(notesByTrade, 'empty', {})
    _.forEach(generalNotes, (lst, sectionId) => {
      _.forEach(lst, (note, noteId) => _.set(res, [sectionId, noteId], note))
    })
    const tradeNotes = _.get(notesByTrade, tradeId, {})
    _.forEach(tradeNotes, (lst, sectionId) => {
      _.forEach(lst, (note, noteId) => _.set(res, [sectionId, noteId], note))
    })
    return res
  }, [notesByTrade])

  const renderSection = (sowItems: object, sectionId: string, index: number) => {
    return (
      <ScopeSection
        key={sectionId}
        sowItems={_.sortBy(sowItems, 'name')}
        sectionId={sectionId}
        index={index}
        sectionName={_.get(sectionsDict, [sectionId, 'name'])}
      />
    )
  }

  const zonesIds = _.sortBy(_.keys(sections), sectionId => _.get(sectionsDict, [sectionId, 'name']))

  const renderNotes = () => {
    const notesSectionsIds = _.sortBy(_.keys(notesSections), sectionId => _.get(sectionsDict, [sectionId, 'name']))
    if (!_.isEmpty(notesSectionsIds)) {
      return (
        <View style={{ flexDirection: 'column', paddingTop: 12 }}>
          <Text style={{ ...styles.textLarge, fontWeight: 'semibold', color: '#004069' }}>General notes</Text>
          {_.map(notesSectionsIds, (sectionId, index) =>
            renderSection(_.get(notesSections, sectionId), sectionId, index)
          )}
        </View>
      )
    }
  }

  if (!_.isNil(accountProfile) && !_.isNil(project) && !_.isNil(tradeId) && !_.isNil(sections)) {
    return (
      <PDFViewer width='100%' height='100%' title='some file name?' onBlob={onBlob}>
        <Document title={filename}>
          <Page size='A4' style={{ flexDirection: 'column', backgroundColor: '#ffffff', padding: 22 }} wrap>
            <ScopeReportHeader accountProfile={accountProfile} project={project} />
            <Text style={{ ...styles.textLarge, paddingTop: 32, textAlign: 'center', fontWeight: 'bold' }}>
              SCOPE OF WORK
            </Text>
            <View style={{ flexDirection: 'column', paddingTop: 12 }}>
              <Text style={{ ...styles.textLarge, fontWeight: 'semibold', color: '#004069' }}>
                {_.get(tradesDict, tradeId)}
              </Text>
              {_.map(zonesIds, (sectionId, index) => renderSection(_.get(sections, sectionId), sectionId, index))}
            </View>
            {renderNotes()}
          </Page>
        </Document>
      </PDFViewer>
    )
  } else {
    return null
  }
}

export default BidPackagePdf
