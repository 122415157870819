import { doc, getDoc } from 'firebase/firestore'
import { db } from 'controllers/db'

import { ProjectDictsT, DBProjectScopeT, ProjectT, AccountProfileT, ProjectDataT } from 'model/types'

export const fetchProjectDicts = async (projectId: string) => {
  const scopeDictsRef = doc(db, 'scopeDicts', projectId)
  const dictSN = await getDoc(scopeDictsRef)
  return dictSN.data() as ProjectDictsT
}

export const fetchProjectScope = async (projectId: string) => {
  const projectScopeRef = doc(db, 'projectScopes', projectId)
  const scopeSN = await getDoc(projectScopeRef)
  return scopeSN.data() as DBProjectScopeT
}

export const fetchProject = async (projectId: string) => {
  const projectRef = doc(db, 'projects', projectId)
  const projectSN = await getDoc(projectRef)
  return projectSN.data() as ProjectT
}

export const fetchAccountProfile = async (accountId: string) => {
  const accountProfileRef = doc(db, 'accountsProfiles', accountId)
  const accountProfileSN = await getDoc(accountProfileRef)
  return accountProfileSN.data() as AccountProfileT
}

export const fetchData = async (projectId: string) => {
  try {
    console.log('fetchProjectDicts')
    const dicts = await fetchProjectDicts(projectId)
    console.log('fetchProjectScope')
    const scope = await fetchProjectScope(projectId)
    console.log('fetchProject')
    const project = await fetchProject(projectId)
    const accountId = project.accountId
    let accountProfile
    if (accountId) {
      console.log('fetchAccountProfile')
      accountProfile = await fetchAccountProfile(accountId)
    }
    return {
      dicts,
      scope,
      project,
      accountProfile
    } as ProjectDataT
  } catch (e) {
    console.error('fetchData error:', e)
    return null
  }
}
