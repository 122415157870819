import { createContext } from 'react'
import { ProjectT, AccountProfileT, DictT, PDetailsT, BaseScopeT, ScopeSectionDictT, NoteT } from 'model/types'

export interface ProjectContextT {
  project: ProjectT | undefined
  accountProfile: AccountProfileT | undefined
  scope: BaseScopeT
  tradesDict: DictT<string>
  sectionsDict: DictT<ScopeSectionDictT>
  detailsDict: DictT<PDetailsT>
  notesDict: DictT<NoteT>
  isDataLoading: boolean
  notesByTrade: DictT<DictT<NoteT>>
}

const Ctx = createContext<ProjectContextT>({
  project: undefined,
  accountProfile: undefined,
  scope: {},
  tradesDict: {},
  sectionsDict: {},
  detailsDict: {},
  notesDict: {},
  isDataLoading: false,
  notesByTrade: {}
})

export default Ctx
