import { FC } from 'react'
import { Center, Progress, VStack, Box, Text } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'assets/masonLogoOnLight.svg'

type Props = {
  note?: string
}

const Loading: FC<Props> = ({ note }) => {
  return (
    <Center h='full' w='full'>
      {/* <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /> */}
      <VStack w='xs' direction='column' align='center' spacing={4}>
        <Logo height='24px' />
        <Box w='full'>
          <Progress size='xs' isIndeterminate />
          {note && (
            <Text textAlign={'center'} fontSize='sm' color={'gray.500'}>
              {note}
            </Text>
          )}
        </Box>
      </VStack>
    </Center>
  )
}

export default Loading
