import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import AppRouter from 'navigation/AppRouter'
import theme from './theme'
import 'react-base-table/styles.css'
import 'src/styles.scss'

export const App = () => (
  <ChakraProvider theme={extendTheme(theme)}>
    <AppRouter />
  </ChakraProvider>
)
