import { useContext, useMemo } from 'react'
import _ from 'lodash'
import { Button } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { ExportToCsv } from 'export-to-csv'
import { useParams } from 'react-router-dom'
import ProjectContext from 'contexts/ProjectContext'

type CsvRowT = {
  Type: string
  Section: string
  'Line item': string
}

const ExportScopeToCsv = () => {
  const { tradeId } = useParams()
  const { tradesDict, project, scope, notesByTrade, sectionsDict } = useContext(ProjectContext)

  const sections = tradeId && _.get(scope, tradeId)

  const notesSections = useMemo(() => {
    if (!tradeId) return {}
    const res = {}
    const generalNotes = _.get(notesByTrade, 'empty', {})
    _.forEach(generalNotes, (lst, sectionId) => {
      _.forEach(lst, (note, noteId) => _.set(res, [sectionId, noteId], note))
    })
    const tradeNotes = _.get(notesByTrade, tradeId, {})
    _.forEach(tradeNotes, (lst, sectionId) => {
      _.forEach(lst, (note, noteId) => _.set(res, [sectionId, noteId], note))
    })
    return res
  }, [notesByTrade])

  const data = useMemo(() => {
    const res: CsvRowT[] = []

    const zonesIds = _.sortBy(_.keys(sections), sectionId => _.get(sectionsDict, [sectionId, 'name']))
    _.forEach(zonesIds, sectionId => {
      const sowItems = _.sortBy(_.get(sections, sectionId), 'name')
      const sectionName = _.get(sectionsDict, [sectionId, 'name'])
      _.forEach(sowItems, item => {
        const r: CsvRowT = {
          Type: 'Scope',
          Section: sectionName,
          'Line item': item.name
        }
        res.push(r)
      })
    })

    const notesSectionsIds = _.sortBy(_.keys(notesSections), sectionId => _.get(sectionsDict, [sectionId, 'name']))
    _.forEach(notesSectionsIds, (sectionId, index) => {
      const sowItems = _.sortBy(_.get(notesSections, sectionId), 'name')
      const sectionName = _.get(sectionsDict, [sectionId, 'name'])
      _.forEach(sowItems, item => {
        const r: CsvRowT = {
          Type: 'General notes',
          Section: sectionName,
          'Line item': item.name
        }
        res.push(r)
      })
    })
    return res
  }, [notesSections])

  const exportData = () => {
    if (tradeId) {
      console.log('exportData')
      const projectAddress = _.get(project, 'address.name', '')
      const filename = `${projectAddress} ${_.get(tradesDict, tradeId, '')} scope`
      const options = {
        filename,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        // showTitle: true,
        // title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
        // headers: ['Section', 'Scope item']
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    }
  }

  return (
    <Button variant={'outline'} leftIcon={<FontAwesomeIcon icon={faDownload} />} onClick={exportData}>
      Export scope to .csv
    </Button>
  )
}

export default ExportScopeToCsv
