import { Flex } from '@chakra-ui/react'

import DashboardContent from 'pages/dashboard/DashboardContent'
import DashboardNavBar from 'pages/dashboard/DashboardNavBar'

const Dashboard = () => {
  return (
    <Flex w='full' h='full' direction='column'>
      <DashboardNavBar />
      <DashboardContent />
    </Flex>
  )
}

export default Dashboard
