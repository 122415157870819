import { Box, Text, Flex } from '@chakra-ui/react'
import { FC, memo, PropsWithChildren } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  itemId: string
  name: string
}

const ScopeItemRow: FC<Props> = ({ itemId, name }) => {
  const renderSowIcon = (itemId: string) => {
    return (
      <>
        <Box color='green.300' pr={2} pl={1}>
          <FontAwesomeIcon icon={faCheck} size='sm' />
        </Box>
      </>
    )
  }

  return (
    <Box key={itemId} py={1} w='full'>
      <Flex direction={'row'} w='fit-content' align='center' gap={2}>
        {renderSowIcon(itemId)}
        <Text textAlign={'left'} key={itemId} color={'blue.600'}>
          {name}
        </Text>
      </Flex>
    </Box>
  )
}

const propsAreEqual = (
  prevProps: Readonly<PropsWithChildren<Props>>,
  nextProps: Readonly<PropsWithChildren<Props>>
) => {
  const r: string[] = []
  _.forEach(prevProps, (v, k) => {
    if (v !== _.get(nextProps, k)) {
      r.push(k)
    }
  })
  const res = _.isEmpty(r) || (_.size(r) === 1 && r[0] === 'toggle')
  return res
}

export default memo(ScopeItemRow, propsAreEqual)
