import { useContext } from 'react'
import { Flex, Heading, Wrap, Box, WrapItem, Text, Container } from '@chakra-ui/react'

import ProjectContext from 'contexts/ProjectContext'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

const DashboardContent = () => {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { tradesDict, scope } = useContext(ProjectContext)

  const tradesIds = _.sortBy(_.keys(scope), tradeId => tradesDict[tradeId])

  return (
    <Flex direction={'column'} align='center'>
      <Flex w='full' justify={'center'} pt={24}>
        <Heading>Which trade are you bidding on?</Heading>
      </Flex>
      <Box maxW={'7xl'} pt={12} pb={12}>
        <Wrap justify={'center'} spacing={4}>
          {_.map(tradesIds, tradeId => {
            return (
              <WrapItem key={tradeId}>
                <Flex
                  w='36'
                  h='36'
                  borderWidth={'1px'}
                  borderColor={'green.200'}
                  align='center'
                  justify={'center'}
                  _hover={{ bgColor: 'green.50' }}
                  as='button'
                  rounded={'base'}
                  onClick={() => navigate(`/${projectId}/${tradeId}`)}
                >
                  <Container>
                    <Text textAlign={'center'} fontWeight={'semibold'}>
                      {tradesDict[tradeId]}
                    </Text>
                  </Container>
                </Flex>
              </WrapItem>
            )
          })}
        </Wrap>
      </Box>
    </Flex>
  )
}

export default DashboardContent
