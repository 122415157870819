import { saveAs } from 'file-saver'
import _ from 'lodash'

export const downloadFile = async (path: string, name: string) => {
  try {
    const request = await window.fetch(path)
    const response = await request.blob()
    const status = _.get(request, 'status')
    if (status === 200) {
      saveAs(response, name)
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log('downloadFile error', error)
  }
}
