import { FC, useContext, useMemo } from 'react'
import { Text, Flex, Button, Heading } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faDownload } from '@fortawesome/pro-regular-svg-icons'
import _ from 'lodash'
import { saveAs } from 'file-saver'

import ProjectContext from 'contexts/ProjectContext'
import BidPackageFiles from 'pages/bidPackage/BidPackageFiles'
import { DictT, FileT } from 'shared/types'
import { downloadFile } from 'controllers/storage'

type Props = {
  blob?: Blob
}

const BidPackageSidePanel: FC<Props> = ({ blob }) => {
  const { tradeId, projectId } = useParams()
  const navigate = useNavigate()
  const { tradesDict, project } = useContext(ProjectContext)

  const projectAddress = _.get(project, 'address.name', '')
  const filename = tradeId ? `${projectAddress} ${_.get(tradesDict, tradeId, '')} scope.pdf` : ''

  const files = useMemo(() => {
    const res: FileT[] = []
    if (tradeId) {
      const projectFiles: DictT<FileT> = _.get(project, 'attachments', {})
      _.forEach(projectFiles, f => {
        const needed = !f.trades || _.includes(f.trades, tradeId)
        if (needed) res.push(f)
      })
    }
    return _.sortBy(res, 'createdAt')
  }, [tradeId, project])

  const onDownloadClick = () => {
    // console.log('onDownloadClick')
    if (blob) {
      saveAs(blob, filename)
      _.forEach(files, f => {
        // console.log('save', f.url, 'name', f.name)
        downloadFile(f.url, f.name)
      })
    }
  }

  return (
    <Flex bgColor='gray.50' w={'44'} maxW='44' h='full' overflow={'hidden'} direction='column'>
      <Flex
        direction='column'
        h='20'
        borderBottomWidth={'1px'}
        borderBottomColor={'gray.300'}
        px={2}
        py={2}
        align='flex-start'
        justify='space-between'
      >
        <Button
          leftIcon={<FontAwesomeIcon icon={faChevronLeft} size='sm' />}
          variant={'link'}
          onClick={() => navigate(`/${projectId}`)}
          maxW='40'
        >
          <Text fontSize={'sm'} isTruncated>
            {tradeId && _.get(tradesDict, tradeId)}
          </Text>
        </Button>
        <Flex direction={'row'} align='center' justify={'space-between'} w='full'>
          <Heading size='sm'>Plan Room</Heading>
          <Button
            size='small'
            variant={'ghost'}
            p={0}
            color='gray.500'
            isDisabled={_.isNil(blob)}
            onClick={onDownloadClick}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Flex>
      </Flex>
      <BidPackageFiles
        filename={filename}
        blob={blob}
        files={files}
        projectEditTime={_.get(project, 'updatedAt', _.get(project, 'createdAt', _.now()))}
      />
    </Flex>
  )
}

export default BidPackageSidePanel
